@import "../../shared/variables.scss";

.timeSheet-header{
    display: flex;
    align-items: center;
    height: 52px;
    @include box-shadow(0px 1px 3px #2020230A);
    padding: 15px;
    &__back{
        display: inherit;
        align-items: center;
        padding-right: 15px;
        &-size{
            font-size: $fontSize25;
        }
    }
    &__title{
        flex: 1 1 auto;
        font-size: $fontSize18;
        font-weight: $fontWeight800;
        color: $headerColor;
        line-height: 25px;
    }
    &__action{
        display: flex;
        align-items: center;
        margin-left: 20px;
        &-size{
            font-size: $fontSize25;
        }
    }
}
.timeSheet-content{
    overflow: auto;
    flex: 1 1 auto;
    height: 100%;
    padding: 15px;
    &__table{
        display: flex;
        flex-direction: column;
        background: #fff;      
        thead{
            margin-bottom: 10px;
            tr{
                display: flex;
                th{
                    width: 90px;
                    font-size: $fontSize14;
                    font-weight: $fontWeight800;
                    text-align: center;
                    color: $headerColor;
                    display: flex;
                    flex: 1;
                    padding: .5em;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        tbody{
            tr{
                display: flex;
                background: $greyBackground;
                @include border-radius(4px);
                border: 1px solid #E9EBED;
                height: 56px;
                text-align: center;
                margin-bottom: 8px;
                align-items: center;
                color: #6B6B6B;
                font-weight: $fontWeight600;
                td{
                    flex: 1;
                    padding: .5em;
                    align-items: center;
                    justify-content: center;
                    font-size: $fontSize16;
                    display: flex;
                    flex-direction: column;
                }
            }
        }  
        &-date{
            font-size: $fontSize20;
            color: #6B6B6B;
        }
        &-day{
            font-size: $fontSize12;
            font-weight: $fontWeight400;
            color: #AEAEB3;
        }
        &-total{
            color: $headerColor;
        }
    }
    table{
        border-collapse: collapse;
    }
}

.timeSheet-footer{
    flex: 0 0 auto;
    height: 124px;
    @include box-shadow(0px -1px 3px #0000000A);
    padding: 20px 15px;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    &__total{
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        margin-bottom: 10px;
        &-text{
            font-size: $fontSize16;
            font-weight: $fontWeight800;
            color: $headerColor;
            display: flex;
            &-preValue{
                color: #6B6B6B;
            }
        }
    }
    &__cancelBtn{
        border: 1px solid #E7EBFA;
        @include border-radius(4px);
        font-size: $fontSize16;
        font-weight: $fontWeight600;
        color: #6B6B6B;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-btnGrid{
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
    }
    &-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }   
    &__arrowIcon{
        width: 16px;
        margin: 0px 8px;
    }
}