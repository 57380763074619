@import "../../../shared/variables.scss";
.FilterModal{
    height: 100%;
    display: flex;
    flex-direction: column;
    &-header{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;   
        color: $headerColor;
        font-weight: $fontWeight800;
        font-size: $fontSize18;
        box-sizing: border-box;
        padding: 20px 0px;
        @include box-shadow(0px 1px 3px #2020230a);
    }
    &-title{
        font-size: 14px;
        font-weight: $fontWeight800;
        color: $headerColor;
        margin: 10px 0px;
    }
    &-content{
        padding: 15px;
        height: 100%;
        overflow: auto;
    }
    &-checkbox{
        padding: 15px 0px;
        .sc-tag3-checkbox-h label.sc-tag3-checkbox{
            font-size: 14px;
            font-weight: $fontWeight400;
            margin-left: 10px;
            color: $headerColor;
            font-family: $fontFamily;
        }
    }
    &-card-checkbox {
        button.tag-checkbox.sc-tag3-checkbox {
          border-color: #acb8cd;
          border-width: 2px;
        }
    }
    &-footer{
        flex: 0 0 auto;
        height: 90px;
        @include box-shadow(0px -1px 3px #0000000A);
        padding: 20px 15px;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        &__total{
            display: flex;
            flex: 1 1 auto;
            justify-content: space-between;
            margin-bottom: 15px;
            &-text{
                font-size: $fontSize16;
                font-weight: $fontWeight800;
                color: $headerColor;
            }
        }
        &-btnGrid{
            display: flex;
            justify-content: space-evenly;
            gap: 20px;
        }
        &-btn{
            border: 1px solid #E7EBFA;
            @include border-radius(4px);
            font-size: $fontSize16;
            color: #6B6B6B;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            &__filled{
                background-color: $themeColor;
                color: #fff;
            }
        }        
    }
}
.mb20{
    margin-bottom: 20px;
}
.mt20{
    margin-top: 20px;
}
.customPopover{
    .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover{
        font-size: 18px;
        font-weight: 800;
        text-align: left;
        color: #070707;
        border-bottom: 1px solid #2020230a;
        margin: 0px;
        padding: 0px 15px 10px;
    }
    .tag3-mobile-slider-popover__dismiss.sc-tag3-mobile-slider-popover{
        display: none;
    }
}