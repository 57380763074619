@import "../../shared/variables.scss";

.myAccount {
	&_header {
		display: flex;
		align-items: center;
		height: 52px;
		@include box-shadow(0px 1px 3px #2020230a);
		padding: 15px;
	}

	&_header-back {
		display: inherit;
		align-items: center;
		padding-right: 15px;

		&-size {
			font-size: $fontSize25;
		}
	}

	&_title {
		flex: 1 1 auto;
		font-size: $fontSize20;
		font-weight: $fontWeight800;
		color: $headerColor;
		line-height: 25px;
	}

	&_action {
		display: flex;
		align-items: center;
		margin-left: 20px;

		&-size {
			font-size: $fontSize25;
		}
	}

	&_form {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		&-content {
			padding: 16px;
			height: 100%;
			overflow: auto;
		}

		&-label {
			font-size: $fontSize14;
			color: $headerColor;
			font-weight: $fontWeight800;
			margin-bottom: 8px;
			display: block;
		}

		&-input {
			position: relative;
			width: 100%;
			.sc-tag3-switch-h {
				padding: 0;
				margin-bottom: 23px;
			}

			.sc-tag3-mobile-dropdown-h .tag-mobile-dropdown.sc-tag3-mobile-dropdown {
				border: 1px solid #ebecf5;
				padding: 10px 15px;
				font-size: 14px;
				outline: none;
				border-radius: 4px;
				height: 40px;
			}

			.sc-tag3-mobile-dropdown-h .tag-mobile-dropdown__remove-label.sc-tag3-mobile-dropdown {
				display: none;
			}

			::placeholder {
				color: #AEAEB3;
			}

			.sc-tag3-switch:checked+.tag-slider-accent--navy.sc-tag3-switch {
				background-color: #68b590;
			}
		}

		&-inputControl {
			margin-bottom: 16px;
		}

		&-input-box {
			width: 100%;
		}

		&-inputIcon {
			position: absolute;
			right: 10px;
			top: 11px;
			font-size: $fontSize25;
			color: #6e6e6e;
		}

		&-button {
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 auto;
			flex-direction: column;
			margin: 16px;
		}

		&-select {
			height: 40px;
			width: 100%;
			position: relative;
			border-radius: 3px;
			margin-bottom: 1em;
		}

		&-select:after {
			content: url("../../images/down_icon.svg");
			padding: 9px 0;
			position: absolute;
			right: 20px;
			top: 0;
			z-index: 1;
			text-align: center;
			width: 12px;
			pointer-events: none;
			height: 7px;

			pointer-events: none;
		}

		&-select__field {
			-webkit-appearance: none;
			appearance: none;
			border: 1px solid #ebecf5;
			width: 100%;
			padding: 10px 15px;
			font-size: 14px;
			color: #070707;
			background: transparent;
			outline: none;
			font-family: "Muli";
			border-radius: 4px;
			-webkit-appearance: none;
			/* for webkit browsers */
			-moz-appearance: none;
			/* for firefox */
			appearance: none;

			/* for modern browsers */
			&:focus {
				outline: none;
			}

			option {
				width: calc(100% - 400px);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		/* remove default caret for ie */

		&-select__field::-ms-expand {
			display: none;
		}

		&-wrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			overflow: auto;
		}
	}
}

select.sc-tag3-mobile-dropdown{
	font-family: Muli;
    font-size: 14px;
	padding: 10px 15px;
}
.sc-tag3-input-h .tag3-input.sc-tag3-input {
	height: 40px;
	font-size: 14px;
	font-family: Muli;
	border-radius: 4px;
}


.myAccount_form-input .sc-tag3-input-h .tag3-input.sc-tag3-input .errorBorder {
	border: 2px solid #EA5876;
	background: #EA58760D;
}

.showErrorMessage{
	.myAccount_form-input-box{
		.tag3-input.sc-tag3-input, .tag-mobile-date-time-input.sc-tag3-mobile-date-time-input{
			border: 2px solid #EA5876;
			background: #EA58760D;
		} 
	}
}

.errorMessage {
	font-size: 14px;
	color: #EA5876;
	margin-top: 8px;
	font-weight: $fontWeight600;
}

