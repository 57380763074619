@import "../../shared/variables.scss";
.notification{
    &_header {
        display: flex;
        align-items: center;
        height: 52px;
        @include box-shadow(0px 1px 3px #2020230a);
        padding: 15px;
    
        &_back {
            display: inherit;
            align-items: center;
            padding-right: 15px;
            &-size {
                font-size: $fontSize25;
            }
        }
        &_title {
            flex: 1 1 auto;
            font-size: $fontSize20;
            font-weight: $fontWeight800;
            color: $headerColor;
            line-height: 25px;
        }
        &_action {
            display: flex;
            align-items: center;
            margin-left: 20px;
            &-size {
                font-size: $fontSize25;
            }
        }
    }
    &_content {
        overflow: auto;
        flex: 1 1 auto;
        height: 100%;
        padding-left: 15px;
        &-pageTitle{
            font-size: $fontSize16;
            color: $headerColor;
            font-weight: $fontWeight800;
            padding: 15px 0px;
        }
    }
    &_card{
        border-top: 1px solid #ccc;
        padding: 15px 15px 15px 10px;
        display: flex;
        align-items: center;
        
        &-icon{
            padding-right: 20px;
            &Bg{
                width: 32px;
                height: 32px;
                background: #EDEEF0;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &-title{
            font-size: $fontSize14;
            color: $headerColor;
            font-weight: $fontWeight400;
            padding-right: 20px;
            line-height: 21px;
            flex: 1;
            span:first-child{
                font-weight: $fontWeight800;
            }
        }

        &-date{
            text-align: right;
            &Text{
                font-size: $fontSize10;
                color: $themeColor;
                font-weight: $fontWeight800;
                
            }
        }
        &-dots{
            margin-top: 10px;
            padding: 0px;
            height: 9px;
        }
    }
}
.deleteNotification{
    padding: 5px 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    &-icon{
        padding-right: 20px;
    }
    &-title{
        font-size: $fontSize14;
        color: $headerColor;
        font-weight: $fontWeight800;
        line-height: 20px;
        span{
            display: block;
            font-weight: $fontWeight400;
        }
    }
    
}

.modalWidth_custom{
    text-align: center;
    .sc-tag3-simple-modal-h .tag-modal-content.device-mobile.sc-tag3-simple-modal {
        margin: 0 40px;
    }
    .tag3-simple-modal-header-container.sc-tag3-mobile-modal{
        font-size: $fontSize18;
        font-weight: $fontWeight800;
        color: $headerColor;
    }
    .dialog-content {
        padding: 10px 32px 20px ;
        display: flex;
        flex-direction: column;
        line-height: 24px;
        font-size: $fontSize16;
        color: #6B6B6B;
    }
    .dialog-btn-group {
        margin-top: 20px;
        gap: 20px;
        .sc-tag3-tertiary-button-h,
        .sc-tag3-simple-button-h {
            width: 100%;
            margin-top: 5px;
            .tag-button {
                width: 100%;
            }
        }
        button.tag-button.device-mobile.sc-tag3-simple-button{
            height: 45px;
            font-size: $fontSize16;
            font-weight: $fontWeight600;
        }
    }
}

@media (prefers-color-scheme: dark){
    .sc-tag3-simple-modal-h .tag-modal-content.colour-scheme-default.sc-tag3-simple-modal {
        color: rgba(255, 255, 255, 0.87);
        background-color: fff;
    }
}