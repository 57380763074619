@import "../../shared/variables.scss";

.navbar {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-x: scroll;
	white-space: nowrap;
	background: #f3f4f8 0% 0% no-repeat padding-box;
	&-btn-group {
		text-transform: capitalize;
		background: #f3f4f8 0% 0% no-repeat padding-box;
		padding: 10px 16px;
	}
	&-btn-group__item {
		border: none;
		padding: 10px;
		margin-right: 10px;
		background: #0000000d 0% 0% no-repeat padding-box;
		cursor: pointer;
		font-size: 10px;
		transition: all 300ms ease-out;
		text-transform: capitalize;
		border-radius: 4px;
		font-weight: 600;
		color: #404040;
		font-family: Muli, Arial;
		height: 30px;
		line-height: 12px;
	}

	&-btn-group__item:hover,
	&-btn-group__item:focus {
		color: #fff;
		background: #555b70 0% 0% no-repeat padding-box;
	}
	&-btn-group__item:focus {
		outline: none;
	}
}

/* Navbar */
.navbar::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}
.navbar-main-pageHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	height: 50px;

	&-icon-size {
		width: 18px;
		height: 18px;
	}
	&-title {
		flex: 1 1 auto;
		font-size: 20px;
		font-weight: 600;
		color: #070707;
		padding: 10px 0;
	}
	&-title span {
		font-weight: 800;
	}
	&-search {
		background-color: #f2f2f2;
		color: #000;
		border-radius: 4px;
		width: 100%;
		margin-right: 20px;
	}
}
.device-mobile.sc-tag3-searchbox-h .tag-searchbox__input.sc-tag3-searchbox {
	background: transparent;
	border-color: transparent;
	height: 35px;
	color: #000;
	font-size: $fontSize12;
	&::-webkit-input-placeholder {
		color: #000;
	}
	&::-moz-input-placeholder {
		color: #000;
	}
	&::placeholder {
		color: #000;
	}
}
.device-mobile.sc-tag3-searchbox-h
	.tag-searchbox__icon-container.sc-tag3-searchbox {
	background: transparent;
	border-color: transparent;
	height: 35px;
	.sc-tag3-icon-svg {
		width: 12px;
		height: 12px;
	}
	svg {
		fill: #000;
	}
}
// Sort Select Bar
.navbar-pageHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f3f4f8 0% 0% no-repeat padding-box;
	padding: 10px 16px 10px;

	&-profileImg {
		background: #fff;
		padding: 6px 10px;
		font-size: 10px;
		margin-left: 5px;
		font-weight: 600;
		border-radius: 4px;
		display: flex;
		align-items: center;
		&-cancel {
			background: #4b4e52 0% 0% no-repeat padding-box;
			color: #fff;
		}
	}
	&-title {
		flex: 1 1 auto;
		font-size: 10px;
		font-weight: 400;
		color: #070707;
		span {
			margin-left: 5px;
		}
	}
	&-title span {
		font-weight: 800;
	}
	&-profileImg img {
		width: 12px;
		height: 12px;
		margin-right: 5px;
	}
	&-card-checkbox {
		button.tag-checkbox.sc-tag3-checkbox{
			border-color: #acb8cd;
			border-width: 2px;
			width: 21px;
			height: 21px;
		}
	}
}

.main-card {
	overflow: auto;
	margin-left: 16px;
	flex: 1 1 auto;
	height: 100%;
	&-containerBox {
		padding: 16px 16px 16px 0;
		position: relative;
		margin-left: 0;
		border-bottom: 1px solid #ccc;
	}
	&-containersBox {
		padding: 16px;
		position: relative;
		border-bottom: 1px solid #ccc;
		margin-left: 12px;
	}
	&-containersBoxes {
		padding: 16px;
		position: relative;
		border-bottom: 1px solid #ccc;
		margin-left: 2px;
	}
	&-new-containerBox {
		padding: 16px;
		position: relative;
		border-bottom: 1px solid #ccc;
		margin-left: 2px;
	}
	&-containersBox .card {
		margin-left: 20px;
	}
	&-containersBox .card-checkbox {
		position: absolute;
		left: -22px;
		width: 21px;
		height: 21px;
		margin-left: 24px;
		padding-right: 5px;
	}
	&-checkbox {
		width: 21px;
		height: 21px;
		position: absolute;
		top: 18px;
		left: -12px;
		button.tag-checkbox.sc-tag3-checkbox {
			border-color: #acb8cd;
			border-width: 2px;
		}
	}

	&-body h3 {
		font-size: 16px;
		font-weight: 900;
		color: #070707;
		margin: 0;
	}
	&-body h4 {
		margin: 2px 0;
		font-size: 14px;
		font-weight: 500;
		color: #070707;
	}
	&-body h5 {
		font-size: 13px;
		font-weight: 400;
		color: #6b6b6b;
		margin: 0;
	}

	&-hours {
		font-size: 13px;
		color: #404040;
		font-weight: 600;
	}
	&-pay {
		font-size: 13px;
		color: #aeaeb3;
	}
	&-date {
		color: #aeaeb3;
		font-size: 10px;
		float: right;
		margin-top: 4px;
	}
	&-status {
		padding: 5px 10px;
		background: #f3f4f8 0% 0% no-repeat padding-box;
		float: right;
		font-size: 10px;
		border-radius: 4px;
		color: #555b70;
		font-weight: 600;
	}
	&-approved-status {
		padding: 5px 10px;
		background: #f3f4f8 0% 0% no-repeat padding-box;
		float: right;
		font-size: 10px;
		border-radius: 4px;
		font-weight: 600;
		color: #68b590;
	}
	&-approved-status {
		color: #68b590;
	}
	&-dot {
		width: 8px;
		height: 8px;
		background-color: #036d83;
		flex: 1 1 auto;
		position: absolute;
		left: 0px;
		top: 22px;
		border-radius: 50px;
	}
	&-name {
		display: flex;
		justify-content: space-between;
	}
	&-errorMessage{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: $fontSize18;
		font-weight: $fontWeight800;
	}
}

.timesheet-popover-sort {
	padding: 16px 16px 0;
	border-bottom: 1px solid #2020230f;
	border-top: 1px solid #2020230f;
	&-option p {
		margin-top: 0;
		font-weight: bold;
		font-size: 14px;
	}
	&-checkbox {
		display: flex;
		height: 45px;
	}
}
.timesheet-popover {
	display: flex;
	justify-content: space-evenly;
	gap: 20px;
	height: 80px;
	padding: 20px 15px;
	&-nav {
		display: flex;
		flex-direction: column;
		width: 171px;
		justify-content: center;
		align-items: center;
	}
}

.tag-radio-button.sc-tag3-radio-button
	label.device-mobile.sc-tag3-radio-button {
	margin-bottom: 10px;
	font-size: 14px;
	color: #070707;
}
.sc-tag3-mobile-slider-popover-h
	.tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
	margin: 0 16px 9px 15px;
}
.sc-tag3-mobile-slider-popover-h
	.tag3-mobile-slider-popover__dismiss.sc-tag3-mobile-slider-popover {
	display: none;
}
.navbar-main-pageHeader-search input.tag3-input.colour-scheme-default.device-desktop.sc-tag3-input {
	width: 280px;
	height: 40px;
	padding-left: 40px;
	margin-right: 16px;
	margin-left: 12px;
	font-size: 12px;
	background-color: #f3f4f8;
	font-weight: 500;
	color: #000000;
}
.navbar-main-pageHeader-profileImg {
	font-size: 12px;
	font-weight: bold;
}

.timesheetFooter-approveBtn {
	border-radius: 4px;
	font-size: 16px;
	color: #fff;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 35px;
	background: #68b590 0% 0% no-repeat padding-box;
}
.timesheetFooter-amendBtn {
	border-radius: 4px;
	font-size: 16px;
	color: #fff;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 35px;
	background: #555b70 0% 0% no-repeat padding-box;
}
.timesheetFooter-approveBtn img {
	width: 16px;
	height: 16px;
}

.timesheet-pageFooter {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px -1px 3px #0000001a;
	flex: 0 0 auto;
	align-items: center;
	height: 80px;
	padding: 20px 15px;
	gap: 20px;
	&-nav {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
}
.leftSpacing {
	margin-left: 20px;
}
.selectedFilterButton {
	background-color: #4b4e52;
	color: #fff;
}

.timeSheet-blankState{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
		align-items: center;
	padding: 0px 20px;
	&__icon{
		width: 56px;
		height: 56px;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		&-green{
			background-color: #EFF8F4;
		}
		&-red{
			background-color: rgba(213, 118, 63, 0.1);
		}
	}
	&__heading{
		font-size: $fontSize22;
		font-weight: $fontWeight800;
		color: $headerColor;
		padding: 30px 0px 15px;
		text-align: center;
	}
	&__subHeading{
		font-size: $fontSize16;
		font-weight: $fontWeight400;
		color: #404040;
		padding-bottom: 10px;
		text-align: center;
	}
	&__description{
		font-size: $fontSize13;
		font-weight: $fontWeight400;
		color: #6B6B6B;
		line-height: 24px;
		text-align: center;
	}
}

.customButton button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button:disabled, .customButton button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button[disabled] {
    border: 1px solid #999999;
    background-color: red;
    color: #fff;
    background: #555B70 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

/* Media Queries */
@media only screen and (max-width: 320px) {
	.tag-searchbox__input.sc-tag3-searchbox {
		width: 200px;
	}
}
