@import '../../shared/variables.scss';

.pageFooter{
    box-sizing: border-box;
    display: flex;
    @include box-shadow(0px -1px 3px #0000001A);
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 20px 15px;
    &-nav{
        display: flex;
        flex-direction: column;
        width: 89px;
        justify-content: center;
        align-items: center;
    }
    &-text{
        font-size: $fontSize11;
        color: $navIconColor;
        font-weight: $fontWeight800;
    }
    &-icon{
        position: relative;
        &__size{
            color: $navIconColor;
            width: 20px;
            height: 20px;
        }
    }
    &-active{
        .pageFooter-text{
            color: $blackColor;
        }
        .pageFooter-icon{
            .pageFooter-icon__size{
                color: $blackColor;
            }
        }
    }
    .notificationDot{
        width: 8px;
        height: 8px;
        background: #EA5876;
        position: absolute;
        border-radius: 10px;
        top: 0px;
        right: -10px;
    }
}