@import "../../shared/variables.scss";

.timeDetails_header {
	display: flex;
	align-items: center;
	height: 52px;
	@include box-shadow(0px 1px 3px #2020230a);
	padding: 15px;

	&_back {
		display: inherit;
		align-items: center;
		padding-right: 15px;
		&-size {
			font-size: $fontSize25;
		}
	}
	&_title {
		flex: 1 1 auto;
		font-size: $fontSize20;
		font-weight: $fontWeight800;
		color: $headerColor;
		line-height: 25px;
	}
	&_action {
		display: flex;
		align-items: center;
		margin-left: 20px;
		&-size {
			font-size: $fontSize25;
		}
	}
}
.timeDetails_content {
	overflow: auto;
	flex: 1 1 auto;
	height: 100%;
	background: $greyBackground;
	&_table {
		display: flex;
		flex-direction: column;
		background: #fff;
		thead {
			margin-bottom: 10px;
			tr {
				display: flex;
				th {
					width: 90px;
					font-size: $fontSize14;
					font-weight: $fontWeight800;
					text-align: center;
					color: $headerColor;
					display: flex;
					flex: 1;
					padding: 0.5em 0.2em;
					align-items: center;
					justify-content: center;
				}
			}
		}
		tbody {
			tr {
				display: flex;
				background: $greyBackground;
				@include border-radius(4px);
				border: 1px solid #e9ebed;
				height: 56px;
				text-align: center;
				margin-bottom: 8px;
				align-items: center;
				color: #6b6b6b;
				font-weight: $fontWeight600;
				td {
					flex: 1;
					padding: 0.5em;
					align-items: center;
					justify-content: center;
					font-size: $fontSize16;
					display: flex;
					flex-direction: column;
				}
			}
		}
		&_date {
			font-size: $fontSize20;
			color: #6b6b6b;
		}
		&_day {
			font-size: $fontSize12;
			font-weight: $fontWeight400;
			color: #aeaeb3;
		}
		&_total {
			color: $headerColor;
		}
	}
	table {
		border-collapse: collapse;
		padding: 15px;
	}
}

.timeDetails_footer {
	flex: 0 0 auto;
	height: 165px;
	@include box-shadow(0px -1px 3px #0000000a);
	padding: 20px 15px;
	box-sizing: border-box;
	position: relative;
	z-index: 10;
	&_total {
		display: flex;
		flex: 1 1 auto;
		justify-content: space-between;
		margin-bottom: 10px;
		&_text {
			font-size: $fontSize16;
			font-weight: $fontWeight800;
			color: $headerColor;
		}
	}
	&_btn {
		&_approve {
			border: 1px solid #e7ebfa;
			@include border-radius(4px);
			font-size: $fontSize16;
			font-weight: $fontWeight400;
			color: #fff;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 4px 0 8px;
		}
		&_query {
			border: 1px solid #e7ebfa;
			@include border-radius(4px);
			font-size: $fontSize16;
			font-weight: $fontWeight400;
			color: #fff;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.recruiter_card {
	background: $greyBackground;

	&_body {
		background: #fff;
		@include border-radius(4px);
		margin: 15px;
		padding: 15px;

		&_name {
			display: flex;
			justify-content: space-between;
			color: $headerColor;
			font-size: $fontSize16;
			font-weight: $fontWeight800;
			& button {
				background: $greyBackground;
				border: none;
				color: $themeColor;
				padding: 8px;
				font-size: $fontSize10;
				@include border-radius(4px);
				font-weight: $fontWeight600;
			}
		}
		&_status {
			padding: 5px 10px;
			background: #f3f4f8 0% 0% no-repeat padding-box;
			float: right;
			font-size: 10px;
			border-radius: 4px;
			color: #555b70;
			font-weight: 600;
		}
		&_dept {
			margin: 0;
			color: $headerColor;
			font-size: $fontSize14;
		}
		&_date {
			color: $textColor;
			margin: 6px 0;
			font-size: $fontSize13;
		}
		&_hours {
			color: $headerColor;
			display: flex;
			justify-content: space-between;
			font-weight: $fontWeight800;
			align-items: center;
			font-size: $fontSize13;

			& span {
				color: $subTextColor;
				font-weight: $fontWeight400;
				font-size: $fontSize13;
				margin-left: 4px;
			}

			& p {
				margin: 0;
				color: $subTextColor;
				font-weight: $fontWeight400;
				font-size: $fontSize10;
			}
		}
	}
}

.timesheetQuery{
		height: 100%;
		display: flex;
		flex-direction: column;
			&_date{
					font-size: $fontSize16;
					font-weight: $fontWeight800;
					text-align: center;
					padding: 15px;
						height: 100%;
						overflow: auto;
			}
			&_label{
					text-align: left;
					color: #070707;
					margin-top: 32px;
					font-size: 14px;
					font-weight: $fontWeight800;
			}
			&_textarea{
					width: 100%;
					textarea#textAreaId {
						width: 100%;
						resize: none;
						border: 1px solid #EBECF5;
						border-radius: 4px;
						height: 120px;
						margin-bottom: 20px;
						font-size: 14px;
						font-family: $fontFamily;
						color: #000;
						&::-webkit-input-placeholder{
							color: #070707;
						}
				}
			}
			&_buttons{
				flex: 0 0 auto;
				height: 100px;
				box-shadow: 0px -1px 3px #0000000a;
				padding: 20px 15px;
				box-sizing: border-box;
				position: relative;
				z-index: 10;
				&_flex{
						display: flex;
						justify-content: space-evenly;
						gap: 20px;
						&_btn{
								border: 1px solid #E7EBFA;
								border-radius: 4px;
								font-size: 16px;
								color: #6B6B6B;
								height: 45px;
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
						}
				}
			}
	}

	.sc-tag3-mobile-slider-popover-h.queryPopover{
		.tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover{
				font-size: $fontSize18;
				font-weight: $fontWeight800;
				text-align: center;
				color: #070707;
				border-bottom: 1px solid #2020230a;
				margin: 0px;
				padding: 0px 10px 10px;
		}
}