@import "../../shared/variables.scss";

.homePage{
    padding: 20px 20px 40px 20px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &-img{
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 237px;
        margin: 0 auto;
        img{
            width: 100%;
        }
    }
    &-link{
        width: 100%;
        @include box-shadow(0px 3px 15px #0000000F);
    }
    &-card{
        width: 100%;
        margin-top: 50px;
        background-color: #fff;
        @include border-radius(8px);
        @include box-shadow(0px 3px 15px #0000000F);
        &__top{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    &_viewtimesheet:first-child{
        border-top: none;
        box-shadow: none;
        border-radius: 8px 8px 0px 0px;
    }
    &_viewtimesheet:last-child{
        border-radius: 0px 0px 8px 8px;
    }
    &_viewtimesheet{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        box-shadow: 0px -1px 0px 0px #e9ebed;
       
        &_viewtimesheets{
            display: flex;
            align-items: center;
            &_title{
                margin-left: 16px;
                font-size: $fontSize16;
                font-weight: $fontWeight800;
                color: $headerColor;
            }
        }
    }
    &__icon:first-child{
        border-right: 1px solid #E9EBED;
    }
        &__icon{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 16px 8px 16px 16px;
            &_dflex{
                display: flex;
                align-items: center;
            }
        
         
            &-size{
                width: 32px;
                height: 32px;
                color: $homeIconColor;
            }
        }
        &__righticon{
            display: flex;
            align-items: start;
            font-size: 18px;
            img{
                transform: scale(-1.4);
            }
        }
        &__title{
            font-size: $fontSize16;
            font-weight: $fontWeight800;
            color: $headerColor;
            margin-left: 16px;
        }
        &__subtext{
            font-size: $fontSize16;
            line-height: 25px;
            color: $subTextColor;
        }
    }
}