@import "../../shared/variables.scss";

.timeEntryForm{
    height: 100%;
    display: flex;
    flex-direction: column;
    &-header{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;   
        color: $headerColor;
        font-weight: $fontWeight800;
        font-size: $fontSize18;
        box-sizing: border-box;
        padding: 20px 0px;
        @include box-shadow(0px 1px 3px #2020230a);
    }
    &-content{
        padding: 15px;
        height: 100%;
        overflow: auto;
    }
    &-label{
        font-size: $fontSize14;
        color: $headerColor;
        font-weight: $fontWeight800;
        margin-bottom: 10px;
    }
    &-input{
        position: relative;
        width: 100%;
        margin-bottom: 15px;
    }
    &-inputControl{
        margin-bottom: 15px;
    }
    &-showlists{
        display: none;
    }
    &-inputBox{
        border: 1px solid #EBECF5;
        width: 100%;
        padding: 10px 15px;
        font-size: $fontSize14;
        color: #070707;
        outline: none;
        font-family: 'Muli';
        margin: 0px;
        @include border-radius(4px);
       
    }
    &-minutestxt{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 135px;
        top: 11px;
        font-size: 14px;
        font-family: 'Muli';
        color: #070707;
    }
    &-break{
        padding: 10px 9px;
        margin-bottom: 12px;
        ::placeholder{
            font-family: muli;
            color: #F3F4F8;
            font-size: $fontSize14;
            font-weight: 300;
            
          }

    }


    
    &-inputIcon{
        position: absolute;
        right: 10px;        
        top: 11px;
    }
    &-actionIcon{
        font-family: 'Muli';
    background: #F3F4F8;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    height: 100%;
    width: 40px;
    font-weight: $fontWeight400;
    color: #555B70;
    font-size: $fontSize16;
        @include border-radius(4px 0px 0px 4px);
    }

    &-plusIcon{
        right: 0px;
        @include border-radius(0px 4px 4px 0px);
        &-text{
           width: 125px;
           right: 0;
           border: none;
           height: 40px;
           bottom: 0px;
        }
    }
    &-checkbox{
        font-size: 16px;
        padding: 15px 0px;
    }



    // ADD BREAKS STYLE

    &-breaks{
        &-list{
            display: flex;
        
        &-lists{
            list-style: none;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // display: block;
            width: 100%;
            &-box{
                background: #F3F4F8 0% 0% no-repeat padding-box;
                border: 1px solid #E9EBED;
                border-radius: 4px;
                padding: 10px 12px;
                margin-bottom: 15px;
                button{
                    border: none;
                }
                span{
                    color: #4B4E51;
                    font-weight: 600;
                    font-size: 16px;
    
                }
            }
        }
    }
        &-lists{
            list-style: none;
            padding: 0;
            display: flex;
            align-items: center;
           flex-wrap: wrap;
            width: 100%;
            margin: 0;
            
            li{
                background: #555B70 0% 0% no-repeat padding-box;
                border-radius: 4px;
                padding: 10px 10px;
                color: #fff;
                display: flex;
                margin: 0 0 14px 0;
                flex: 0 0 32%;
                max-width: 32%;
                align-items: center;
                justify-content: space-between;
                &:nth-child(3n-1){
                    margin-left: 6px;
                    margin-right: 6px;
                }
                button{
                    border: none;
                    color: #fff;
                    background: transparent;
                    padding: 0;
                    width: 12px;
                    height: 12px;
                    img{
                        width: 12px;
                    }
                }
            }
        }
    }

    &-footer{
        flex: 0 0 auto;
        height: 124px;
        @include box-shadow(0px -1px 3px #0000000A);
        padding: 20px 15px;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        &__total{
            display: flex;
            flex: 1 1 auto;
            justify-content: space-between;
            margin-bottom: 15px;
            &-text{
                font-size: $fontSize16;
                font-weight: $fontWeight800;
                color: $headerColor;
            }
        }
        &-btnGrid{
            display: flex;
            justify-content: space-evenly;
            gap: 20px;
        }
        &-btn{
            border: 1px solid #E7EBFA;
            @include border-radius(4px);
            font-size: $fontSize16;
            color: #6B6B6B;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            &__filled{
                background-color: $themeColor;
                color: #fff;
            }
        }        
    }
}

.addtimeentry.customPopover{
    .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover{
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: #070707;
        border-bottom: 1px solid #2020230a;
        margin: 0px;
        padding: 0px 10px 10px;
        text-align: center;
    }
    .tag3-mobile-slider-popover__dismiss.sc-tag3-mobile-slider-popover{
        display: none;
    }
}
.customButtonNew {
    width: 100%;
    button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button {
      height: 45px;
      font-size: $fontSize16;
      width: 100%;
      background: #f3f4f8 0% 0% no-repeat padding-box;
      border: 1px solid #e9ebed;
      border-radius: 4px;
      color: #555b70;
    }
}
.custom_alert {
    position: fixed;
    bottom: 26px;
    left: 20px;
    width: 90%;
    z-index: 101;
}
.z-indexed{
    z-index: 9999;
}