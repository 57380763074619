$fontFamily: Muli, Arial;

$themeColor : #555B70;
$borderColor: #E7E9EE;
$headerColor: #070707;
$greenColor: #68B590;
$greyBackground: #F3F4F8;
$blackColor: #000000;
$homeBackground: #F9FAFA;
$navIconColor: #8C9097;
$homeIconColor: #EA5876;
$subTextColor: #AEAEB3;
$buttonBorder: #E7EBFA;
$textColor: #6B6B6B;

$fontSize25: 25px;
$fontSize22: 22px;
$fontSize20: 20px;
$fontSize18: 18px;
$fontSize16: 16px;
$fontSize14: 14px;
$fontSize13: 13px;
$fontSize12: 12px;
$fontSize11: 11px;
$fontSize10: 10px;

$fontWeight800: 800;
$fontWeight600: 600;
$fontWeight400: 400;


@mixin border-radius($pixel) {
    border-radius: $pixel;
}
@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;       
    box-shadow: $shadow;
}