@import "../../shared/variables.scss";

.placement_details_header{
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0px 1px 3px #2020230A;
    &_title{
        display: flex;
        align-items: center;
        font-size: $fontSize20;
        color: #070707;
        font-weight: $fontWeight600;
        flex: 1 1 auto;
        img{
            margin-right: 8px;
        }
    }
    &_addicon{
        opacity: 1;
        &_hidden{
            opacity: 0.3;
            
        }
    }
}
.placement_detail{
    overflow: auto;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    &_toastmessage{
        .tag3-mobile-alert.sc-tag3-mobile-alert-h .tag3-mobile-alert--default.sc-tag3-mobile-alert{
            margin: 0;
            border-radius: 0;
            padding: 0 16px;
            background: #2E72D2;
            
        }
        .tag3-mobile-alert.sc-tag3-mobile-alert-h .tag3-mobile-alert__text.sc-tag3-mobile-alert{
            font-size: 16px;
            font-family: Muli, Arial;
            font-weight: normal;
        }
        .tag3-mobile-alert.sc-tag3-mobile-alert-h .tag3-mobile-alert__content.sc-tag3-mobile-alert{
            padding: 0;
        }
      
    }
    &_toastmessage{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 1;
    }
    &_info{
        background: #F2F3F7;
        padding: 15px;
        &_hidden{
            opacity: 0.3;
            margin-top: 2px;
        }
    
    &_shift{
        background: #fff;
        border-radius: 8px;
        &_time{
            padding: 15px;
            border-bottom: 1px solid #F2F3F7;
            p{
                color: #6B6B6B;
                font-size: $fontSize13;
                font-weight: $fontWeight400;
                margin: 0;
                padding: 0;
            }
            h5{
                font-size: $fontSize20;
                font-weight: $fontWeight800;
                margin: 0;
                padding: 0;
            }
        }
        &_date{
            padding: 15px;
           
            p{
                display: flex;
                align-items: center;
                font-size: $fontSize14;
                color: #070707;
                font-weight: $fontWeight600;
                margin: 0;
                padding: 0;
                margin-bottom: 8px;
                &:last-child{
                    margin-bottom: 0;
                }
               
                img{
                    margin-right: 15px;
                }
            }
        }
    }
}
    &_user{
        background: #fff;
        &_info{
            padding: 15px;
            p{
                display: flex;
                align-items: center;
                font-size: $fontSize14;
                color: #070707;
                font-weight: $fontWeight600;
                margin: 0;
                padding: 0;
                margin-bottom: 18px;
               
                img{
                    margin-right: 15px;
                }
               
            }
            &_status{
                span{
                
                border-radius: 4px;
                font-size: $fontSize10;
                color: #fff;
                padding: 2px 8px;
                margin: 0 8px;}
                &_completed {
                    background: #68b590 0% 0% no-repeat padding-box;
                }
                &_inprogress {
                    background: #e8ad44;
                }
                &_scheduled {
                    background: #02aff7;
                }
                &_noshow {
                    background: #ea5876;
                }
            }
        }
        
    }
    
}