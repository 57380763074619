@import "../../shared/variables.scss";

.time_tracker_header {
	display: flex;
	align-items: center;
	height: 52px;
	box-shadow: 0px 1px 3px #2020230a;
	padding: 15px;
	background: #fff;
	&_title {
		flex: 1 1 auto;
		font-size: 20px;
		font-weight: 800;
		color: #070707;
		line-height: 25px;
	}
	&_icons {
		display: flex;
		align-items: center;
		&_rightarrow {
			transform: scale(-1);
			margin: 0 16px 0 12px;
		}
		&_spreadsheet {
			width: 16px;
		}
	}
	&_backarrow{
		margin-left: -10px;
	}
}

.time_tracker_filter {
	display: flex;
	background: #f3f4f8;
	padding: 15px;

	&_time {
		flex: 1 1 auto;
		&_title {
			flex: 1 1 auto;
			color: #070707;
			font-size: $fontSize13;
			font-weight: $fontWeight800;
			margin-bottom: 8px;
		}
		&_shifts {
			display: flex;
			&_shift {
				font-size: $fontSize10;
				&:first-child {
					margin-right: 14px;
				}
			}
		}
		&_filters {
			background-color: #ffffff;
			border-radius: 4px;
			padding: 12px;
			font-size: 10px;
			font-weight: bold;
			display: flex;
			align-items: center;
			&_img {
				margin: 0 9px 0 0;
			}
		}
	}
}
.selectedFilterButton {
    background-color: #4b4e52;
    color: #fff;
}

.time_tracker_infos {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 15px;
	background: #f3f4f8;
	justify-content: space-between;
	border-bottom: 1px solid #e8e9ee;
	&_box {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 15px #0000000f;
		border-radius: 8px;
		padding: 14px;
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		width: 48.5%;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 0 12px;

		&_text {
			color: #6b6b6b;
			font-size: 13px;
			font-weight: normal;
			p {
				color: #070707;
				font-weight: bold;
				margin: 4px 0 0 0;
				padding: 0;
				font-size: 16px;
			}
		}
		&_icon {
			border-radius: 50%;
			padding: 8px;
			display: flex;
			align-items: center;
			width: 32px;
			height: 32px;
			justify-content: center;
			background: #68b590;
			&_progress {
				background: #e8ad44;
			}
			&_schedule {
				background: #02aff7;
			}
			&_noshow {
				background: #ea5876;
			}
			&_img {
				background: #fff;
			}
		}
	}
}

.timetracker-main-card {
	overflow: auto;
	flex: 1 1 auto;
	height: 100%;
	overscroll-behavior: none;

	&-shifts {
		background: #fff;
		&-time {
			padding: 8px 15px;
			background-color: #f2f3f7;
			font-size: $fontSize13;
			color: #070707;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&-count {
			color: #6b6b6b;
			font-size: $fontSize10;
			font-weight: $fontWeight800;
		}
	}
	&-containerBox {
		background: #fff;
		border-bottom: 1px solid #E9EBED;
		margin-left: 16px;
		&:last-child {
			border-bottom: none;
		}
	}
	&-body {
		padding: 16px 16px 16px 0;
	}
	&-location {
		margin: 3px 0;
		color: #070707;
		font-size: $fontSize14;
		font-weight: normal;
	}

	&-name {
		color: #070707;
		font-size: $fontSize16;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: $fontWeight800;
		span {
			border-radius: 4px;
			color: #fff;
			font-size: 10px;
			padding: 5px 16px;
			font-weight: 600;
		}
	}
	&-names {
		color: #070707;
		font-size: $fontSize16;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: $fontWeight800;
		h5 {
			color: #6b6b6b;
			font-size: $fontSize13;
			font-weight: normal;
			padding: 0;
			margin: 0;
		}
	}
	&-label {
		font-size: $fontSize10;
		color: #68b590;
		font-weight: $fontWeight600;
	}
	&-completed {
		background: #68b590 0% 0% no-repeat padding-box;
	}
	&-inprogress {
		background: #e8ad44;
	}
	&-scheduled {
		background: #02aff7;
	}
	&-noshow {
		background: #ea5876;
	}
}
