@import "../src/shared//variables.scss";

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}
body {
    -webkit-overflow-scrolling: touch;
}
html,
body {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: $fontFamily;
    background: #fff; //Android
    // position: fixed;
    width: 100%;
}
html {
    position: fixed;
    height: 100%;
    overflow: hidden;
}

body {
    width: 100vw;
    -webkit-overflow-scrolling: touch;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    -webkit-overflow-scrolling: touch

    &-body {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-footer {
        flex: 0 0 auto;
        z-index: 3;
    }

    &-page {
        flex: 1 1 auto;
        height: 100%;
        background-color: $homeBackground;
        overflow: auto;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:focus {
            outline: none;
            background-color: transparent;
        }
    }
}

.text-center {
    text-align: center;
}

.hideShow {
    display: none;
}

@media (min-width: 1025px) {
    .App {
        display: none;
    }

    .hideShow {
        display: block;
    }
}

.width100 {
    width: 100%;
}

.customButton {
    width: 100%;

    button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button {
        height: 45px;
        font-size: $fontSize16;
        width: 100%;
        padding: inherit;
    }
}

.customTernaryButton {
    width: 100%;

    button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button {
        height: 45px;
        font-size: $fontSize16;
        width: 100%;
        background: #fff;
        border: 1px solid #E7EBFA;
        color: #6B6B6B;
    }
}

.motionDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.colour-scheme-dark {
    .sc-tag3-mobile-side-menu-h .tag3-mobile-side-menu__panel.sc-tag3-mobile-side-menu {
        background: #fff;
        border: none;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }


    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }

    .sc-tag3-checkbox-h .tag-checkbox.sc-tag3-checkbox {
        border: 1px solid #e2ecef;
        background-color: #fff;
        fill: #fff;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }
    &.sc-tag3-simple-modal-h {
        background-color: rgba(0, 0, 0, 0.5);
    }
    &.sc-tag3-mobile-side-menu-h .tag3-mobile-side-menu__panel.sc-tag3-mobile-side-menu {
        background: #fff;
        border: none;
    }
    &.sc-tag3-checkbox-h .tag-checkbox.sc-tag3-checkbox {
        border: 1px solid #e2ecef;
        background-color: #fff;
        fill: #fff;
        border-width: 2px;
        border-color: #acb8cd;
    }
    &.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }
    &.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }
    &.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }
    &.sc-tag3-mobile-slider-popover-h {
        background-color: rgba(0, 0, 0, 0.5);
    }
    
    .sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input__placeholder.sc-tag3-mobile-date-time-input {
        color: #AEAEB3;
    }
    .sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input__value-label.sc-tag3-mobile-date-time-input{
        color: #070707;
    }
}

.tag-radio-button.colour-scheme-dark.sc-tag3-radio-button .tag-radio-button__radiolabel.device-mobile.sc-tag3-radio-button {
    color: #070707;
}

.sc-tag3-simple-modal-h .tag-modal-content.colour-scheme-dark.sc-tag3-simple-modal {
    color: #b4b1ad;
    background-color: #fff;
}

@media (prefers-color-scheme: dark) {
    
    .colour-scheme-default.sc-tag3-mobile-slider-popover-h {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }

    .colour-scheme-dark.sc-tag3-checkbox-h .tag-checkbox.sc-tag3-checkbox {
        border: 1px solid #e2ecef;
        background-color: #fff;
        fill: #fff;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }

    .colour-scheme-dark.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }
    .colour-scheme-default.sc-tag3-checkbox-h .tag-checkbox.sc-tag3-checkbox {
        border: 2px solid #acb8cd;
        background-color: #fff;
        fill: #fff;
    }
    .tag-radio-button.colour-scheme-default.sc-tag3-radio-button .tag-radio-button__radiolabel.device-mobile.sc-tag3-radio-button {
        color: #070707;
    }
    .colour-scheme-default.sc-tag3-mobile-side-menu-h {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .colour-scheme-default.sc-tag3-mobile-side-menu-h .tag3-mobile-side-menu__panel.sc-tag3-mobile-side-menu {
        background: #fff;
    }
    .colour-scheme-default.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__drag.sc-tag3-mobile-slider-popover span.sc-tag3-mobile-slider-popover {
        background-color: #e2ecef;
    }
    .colour-scheme-default.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
        color: #070707;
    }
    .sc-tag3-textarea-h .tag3-textarea.colour-scheme-default.sc-tag3-textarea {
        background-color: #fff;
    }
    .colour-scheme-default.sc-tag3-mobile-slider-popover-h .tag3-mobile-slider-popover__panel.sc-tag3-mobile-slider-popover {
        background: #fff;
        border: none;
    }
    .colour-scheme-default.sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input__value-label.sc-tag3-mobile-date-time-input{
        color: #070707;
    }
    .colour-scheme-default.sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input.sc-tag3-mobile-date-time-input {
        border-bottom: 1px solid #EBECF5;
    }
    .sc-tag3-input-h .tag3-input.colour-scheme-default.sc-tag3-input{
        background-color: #fff;
        border: 1px solid #EBECF5;
        color: #4b4e52;
        &:focus{
            border-color: #EBECF5;
        }
    }
    .sc-tag3-textarea-h .tag3-textarea.colour-scheme-default.sc-tag3-textarea{
        background-color: #fff;
        border: 1px solid #EBECF5;
        color: #4b4e52;
        &:focus{
            border-color: #EBECF5;
        }
    }
    .colour-scheme-default.sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input__placeholder.sc-tag3-mobile-date-time-input {
        color: #AEAEB3;
    }
    .colour-scheme-default.sc-tag3-mobile-dropdown-h .tag-mobile-dropdown__value.sc-tag3-mobile-dropdown {
        color: #4b4e52;
    }
}