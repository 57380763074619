@import "../../shared/variables.scss";

.manual_checkin {
	height: 100%;
	display: flex;
	flex-direction: column;
	&_content {
		padding: 15px;
		height: 100%;
		overflow: auto;
		&_selectapp {
			background: #f3f4f8 0% 0% no-repeat padding-box;
			display: flex;
			margin-bottom: 15px;
			align-items: center;
			justify-content: space-between;
			border-radius: 4px;
			&_texts {
				display: flex;
				align-items: center;
				&_box {
					background: #ffffff 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000008;
					border-radius: 4px;
					margin: 8px;
					position: relative;
					display: flex;
					align-items: center;
					width: 40px;
					height: 44px;
					justify-content: center;
					&_tags {
						width: 20px;
					}
					&_info {
						position: absolute;
						top: -5px;
						right: -4px;
					}
				}
				&_select {
					margin-left: 10px;
					b {
						margin: 0%;
						color: #070707;
						font-size: $fontSize14;
						line-height: 0;
					}
					p {
						margin: 0;
						color: #6b6b6b;
						font-size: $fontSize12;
					}
				}
			}
			&_clickable {
				img {
					transform: scale(-1);
					margin-right: 10px;
				}
			}
		}
		&_breaks {
			&_label {
				font-size: 14px;
				color: #070707;
				font-weight: 800;
				margin-bottom: 10px;
			}
			&_inputControl {
				margin-bottom: 15px;
			}
			&_input {
				position: relative;
				width: 100%;
				margin-bottom: 15px;
			}
			&_inputBox {
				border: 1px solid #ebecf5;
				width: 100%;
				padding: 10px 15px;
				font-size: 14px;
				color: #070707;
				outline: none;
				font-family: "Muli";
				border-radius: 4px;
				margin: 0px;
			}
			&_actionIcon {
				font-family: "Muli";
				background: #f3f4f8;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0px;
				height: 100%;
				width: 40px;
				font-weight: 400;
				color: #555b70;
				font-size: 16px;
				border-radius: 4px 0px 0px 4px;
			}
			&_plusIcon {
				right: 0px;
				border-radius: 0px 4px 4px 0px;
				&_text {
					width: 110px;
					right: 0;
					border: none;
				}
			}

			// ADD BREAKS STYLES

			&_list {
				display: flex;

				&_lists {
					list-style: none;
					padding: 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					// display: block;
					width: 100%;
					&_box {
						background: #f3f4f8 0% 0% no-repeat padding-box;
						border: 1px solid #e9ebed;
						border-radius: 4px;
						padding: 10px 12px;
						margin-bottom: 15px;
						button {
							border: none;
						}
						span {
							color: #4b4e51;
							font-weight: 600;
							font-size: 16px;
						}
					}
				}
			}
			&_break{
				padding: 10px 9px;
				margin-bottom: 12px;
			}
			&_minutestxt{
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				right: 36%;
				top: 11px;
				font-size: 14px;
				font-family: 'Muli';
				color: #070707;
			}
			&_lists {
				list-style: none;
				padding: 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;
				margin: 0;

				li {
					background: #555b70 0% 0% no-repeat padding-box;
					border-radius: 4px;
					padding: 10px 10px;
					color: #fff;
					display: flex;
					margin: 0 0 14px 0;
					flex: 0 0 32%;
					max-width: 32%;
					align-items: center;
					justify-content: space-between;
					&:nth-child(3n-1) {
						margin-left: 6px;
						margin-right: 6px;
					}
					button {
						border: none;
						color: #fff;
						background: transparent;
						padding: 0;
						width: 12px;
						height: 12px;
						img {
							width: 12px;
						}
					}
				}
			}
		}
		&_appmessage {
			background: #f4f8fd 0% 0% no-repeat padding-box;
			border: 1px solid #96b8e8;
			border-radius: 4px;
			padding: 14px 16px;
			margin: 15px 0;
			&_icon {
				display: flex;
				align-items: center;
				span {
					color: #383b41;
					font-size: $fontSize14;
					font-weight: normal;
					margin-left: 16px;
				}
			}
		}
	}
	&_footer {
		flex: 0 0 auto;
		height: 124px;
		box-shadow: 0px -1px 5px #0000000f;
		padding: 20px 15px;
		box-sizing: border-box;
		position: relative;
		z-index: 10;
		&__total {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			margin-bottom: 15px;
			&_text {
				font-size: 16px;
				font-weight: 800;
				color: #070707;
			}
		}
		&_btnGrid {
			display: flex;
			justify-content: space-evenly;
			gap: 20px;
		}
		&_btn {
			border: 1px solid #e7ebfa;
			border-radius: 4px;
			font-size: 16px;
			color: #6b6b6b;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
	}
}

// popover

.select_app {
	height: 100%;
	display: flex;
	flex-direction: column;
	&_content {
		padding: 15px;
		height: 100%;
		overflow: auto;
	}
	&_footer {
		flex: 0 0 auto;
		height: 92px;
		box-shadow: 0px -1px 5px #0000000f;
		padding: 20px 15px;
		box-sizing: border-box;
		position: relative;
		z-index: 10;
		&_btnGrid {
			display: flex;
			justify-content: space-evenly;
			gap: 20px;
		}
		&_btn {
			border: 1px solid #e7ebfa;
			border-radius: 4px;
			font-size: 16px;
			color: #6b6b6b;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
	}
}

.selectApp.customPopover {
	.tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
		font-size: 18px;
		font-weight: 800;
		text-align: center;
		color: #070707;
		border-bottom: 1px solid #2020230a;
		margin: 0 0 10px 0;
		padding: 0px 10px 10px;
		text-align: center;
	}
	.tag-radio-button.sc-tag3-radio-button
		label.device-mobile.sc-tag3-radio-button {
		margin-bottom: 15px;
	}
	.tag-radio-button__radiolabel.device-mobile.sc-tag3-radio-button
		.tag-radio-button__radiolabelcheck.sc-tag3-radio-button.sc-tag3-radio-button {
		margin-right: 12px;
	}
}
.manualWorker.customPopover {
	.tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
		font-size: 18px;
		font-weight: 800;
		text-align: center;
		color: #070707;
		border-bottom: 1px solid #2020230a;
		margin: 0px;
		padding: 0px 10px 10px;
		text-align: center;
	}
}
.customButton.Checkin
	button.tag-button.device-mobile.tag-simple-button-size--default.sc-tag3-simple-button {
	background: #f3f4f8 0% 0% no-repeat padding-box;
	border-radius: 4px;
	border: none;
}
.custom_alert {
	position: fixed;
	bottom: 26px;
	left: 20px;
	width: 90%;
	z-index: 101;
}
.z-indexed{
	z-index: 99999;
}
.heightAuto{
	height: 40px;
}