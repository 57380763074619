@import "../../shared/variables.scss";

.history_card{
    padding: 10px 0 8px;
    background: $greyBackground;
    height: 100%;
    &_body{
        background: #fff;
        @include border-radius(4px);
        padding: 15px;
        margin: 10px 15px 3px;
        &_head{
            font-size: $fontSize14;
            font-weight: $fontWeight800;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & span{
                color: $subTextColor;
                font-size: $fontSize10;
                font-weight: $fontWeight400;
            }

        }
        &_name{
            color: $subTextColor;
            font-size: $fontSize10;
            margin-bottom: 0;
            margin-top: 8px;
        }
        &_message{
            color: $blackColor;
            font-size: $fontSize13;
            margin: 8px 0 0;
            font-weight: $fontWeight400;
        }
    }
}


.sticky{
    position: sticky;
    top: 0;
}