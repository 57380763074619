@import "../../shared/variables.scss";

.amend_header {
	display: flex;
	align-items: center;
	height: 54px;
	@include box-shadow(0px 1px 3px #2020230a);
	padding: 15px;

	&_back {
		display: inherit;
		align-items: center;
		margin-left: -10px;
		margin-right: 10px;
		&_size {
			font-size: $fontSize25;
		}
	}
	&_title {
		flex: 1 1 auto;
		font-size: $fontSize20;
		font-weight: $fontWeight800;
		color: $headerColor;
		line-height: 25px;
	}
	&_action {
		display: flex;
		align-items: center;
		margin-left: 20px;
		&_size {
			font-size: $fontSize25;
		}
	}
}
.amend_content {
	overflow: auto;
	flex: 1 1 auto;
	height: 100%;
	background: #fff;
	&_table {
		display: flex;
		flex-direction: column;
		background: #fff;
		height: 100%;
		thead {
			margin-bottom: 10px;
			tr {
				display: flex;
				height: 72px;
				border-bottom: 1px solid #e9ebed;

				th {
					width: 90px;
					font-size: $fontSize14;
					font-weight: $fontWeight800;
					text-align: center;
					color: $headerColor;
					display: flex;
					flex: 1;
					padding: 0.5em 0.2em;
					align-items: center;
					justify-content: center;
				}
			}
		}
		tbody {
			tr {
				display: flex;
				background: #fff;
				border-bottom: 1px solid #e9ebed;
				height: 72px;
				text-align: center;
				margin-bottom: 8px;
				align-items: center;
				color: #6b6b6b;
				font-weight: $fontWeight600;
				td {
					flex: 1;
					padding: 0.5em;
					align-items: center;
					justify-content: center;
					font-size: $fontSize16;
					display: flex;
					flex-direction: column;
				}
			}
		}
		&_endtime {
			color: #6b6b6b;
			font-size: $fontSize16;
		}
		&_ratings {
			position: relative;
			width: 100%;
		}
		&_paydate {
			color: $subTextColor;
			font-size: $fontSize12;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
		}
		&_payrate {
			color: $headerColor;
			font-size: $fontSize16;
			font-weight: $fontWeight600;
		}
		&_starttime {
			color: #6b6b6b;
			font-size: $fontSize16;
		}
		&_image {
			border: 1px solid #dbdfe4;
			border-radius: 50%;
			height: 32px;
			width: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&_date {
			font-size: $fontSize20;
			color: #6b6b6b;
		}
		&_day {
			font-size: $fontSize12;
			font-weight: $fontWeight400;
			color: #aeaeb3;
		}
		&_total {
			color: $headerColor;
		}
	}
	table {
		border-collapse: collapse;
	}
}

.amend_footer {
	flex: 0 0 auto;
	height: 130px;
	@include box-shadow(0px -1px 3px #0000000a);
	padding: 20px 15px;
	box-sizing: border-box;
	position: relative;
	z-index: 10;
	&_total {
		display: flex;
		flex: 1 1 auto;
		justify-content: space-between;
		margin-bottom: 10px;
		&_text {
			font-size: $fontSize16;
			font-weight: $fontWeight800;
			color: $headerColor;
		}
	}
}

.amend_pophover {
	height: 100%;
	display: flex;
	flex-direction: column;
	.customPopover
		.tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
		font-size: 18px;
		font-weight: 800;
		text-align: center;
		color: #070707;
		border-bottom: 1px solid #2020230a;
		margin: 0px;
		padding: 0px 10px 10px;
	}
	&_contents .tag3-mobile-slider-popover__title.sc-tag3-mobile-slider-popover {
		text-align: center;
	}
	&_content {
		height: 100%;
		overflow: auto;
		&_box {
			&_day {
				text-align: center;
				font-weight: $fontWeight800;
				font-size: $fontSize16;
				margin: 14px 0 4px;
				color: #070707;
			}
			&_content {
				background: #f3f9fc 0% 0% no-repeat padding-box;
				border-radius: 4px;
				margin: 8px 16px 16px 16px;
				display: flex;
				padding: 11px 10px 14px 14px;

				p {
					font-size: $fontSize12;
					margin: 0;
					line-height: 1.6;
					flex: 1;
					margin-right: 30px;
					color: #000;
				}
				span {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
		&_times {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: baseline;
			gap: 15px;
			padding: 15px;

			&_timings {
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				width: 47%;
				&_input {
					position: relative;
				}

				&_inputBox {
					border: 1px solid #ebecf5;
					width: 100%;
					padding: 10px 15px;
					font-size: 14px;
					color: #000;
					outline: none;
					border-radius: 4px;
				}
				&_actionIcon {
					background: #e7e7ec;
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 0px;
					height: 38px;
					width: 40px;
					border-radius: 4px 0px 0px 4px;
				}
				&_plusIcon {
					right: 0px;
					border-radius: 0px 4px 4px 0px;
				}
				&_label {
					font-size: 14px;
					color: #070707;
					font-weight: 800;
					margin-bottom: 10px;
					display: flex;
					align-items: center;
					margin-top: 0;
				}
				&_break {
					&_texts {
						font-size: 14px;
						color: #000;
						font-weight: $fontWeight400;
						margin-bottom: 10px;
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}

	&_footer {
		flex: 0 0 auto;
		height: 124px;
		@include box-shadow(0px -1px 5px #0000000f);
		padding: 20px 15px;
		box-sizing: border-box;
		position: relative;
		z-index: 10;
		&_total {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			margin-bottom: 15px;
			&_text {
				font-size: $fontSize16;
				font-weight: $fontWeight800;
				color: $headerColor;
			}
		}
		&_btnGrid {
			display: flex;
			justify-content: space-evenly;
			gap: 20px;
		}
		&_btn {
			border: 1px solid #e7ebfa;
			@include border-radius(4px);
			font-size: $fontSize16;
			color: #6b6b6b;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			&_filled {
				background-color: $themeColor;
				color: #fff;
			}
		}
	}
}
