@import "../../shared/variables.scss";

.getStarted{
    &-wrapper{
        background-color: $greyBackground;
        height: 100%;
        &__show{
            display: block;
            height: 100%;
        }
        &__hide{
            display: none;
        }
    }
    display: flex;
    height: 100%;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    &-img{
        margin-top: 40px;
        &__main{
            background: url("../../images/getStartedImgBg.svg") center center no-repeat;
            width:100%;
            height: 375px;
            background-size: cover;
           
        }
        &__inner{
            background: url("../../images/getStartedImg.svg") center center no-repeat;
            width:278px;
            height: 212px;
            background-size: cover;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-text{
        padding: 0px 20px 20px;
        text-align: center;
        &__header{
            font-size: $fontSize22;
            color: $headerColor;
            line-height: 28px;
            font-weight: $fontWeight800;
        }
        &__subtext{
            font-size: $fontSize16;
            color: #404040;
            line-height: 24px;
            margin-top: 20px;
        }
        
    }
    &-button{
        margin: 0px 20px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        flex-direction: column;
        &__skip{
            padding: 20px 0px 60px;
        }
    }
    &-splash{
        background: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 60px 0px;
        &_logo{
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            img{
                width: 200px;
            }
        }
        &_text{
            font-size: $fontSize16;
            color: #8C9097;
            align-items: center;
            display: flex;
            span{
                font-size: 20px;
                color: #4E5054;
                margin-left: 5px;
                align-items: center;
                margin-top: 1px;
                display: flex;
            }
        }
    }
}
