@import "../../shared/variables.scss";

.export-attendance-report {
  &_header {
	display: flex;
	align-items: center;
	height: 52px;
	@include box-shadow(0px 1px 3px #2020230a);
	padding: 15px;
  }

  &_header-back {
	display: inherit;
	align-items: center;
	padding-right: 15px;

	&-size {
	  font-size: $fontSize25;
	}
  }

  &_title {
	flex: 1 1 auto;
	font-size: $fontSize18;
	font-weight: $fontWeight800;
	color: $headerColor;
	line-height: 25px;
  }

  &_action {
	display: flex;
	align-items: center;
	margin-left: 20px;

	&-size {
	  font-size: $fontSize25;
	}
  }

  &_form {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;

	&-content {
	  padding: 16px;
	  height: 100%;
	  overflow: auto;
	}

	&-label {
	  font-size: $fontSize14;
	  color: $headerColor;
	  font-weight: $fontWeight800;
	  margin-bottom: 8px;
	  display: flex;
	  justify-content: space-between;

	  &-span {
		font-weight: 500;
		color: #070707;
	  }
	}

	&-input {
	  position: relative;
	  width: 100%;

	  // .tag-slider.sc-tag3-switch {
	  // 	background-color: #555b70;
	  // }
	  .sc-tag3-textarea-h{
		width: 100%;
	  }
	  .sc-tag3-textarea-h .tag3-textarea.sc-tag3-textarea{
		height: 80px;
		width: 100%;
		resize: none;
	  }
	  .sc-tag3-switch-h {
		padding: 0;
		margin-bottom: 23px;
	  }

	  .sc-tag3-mobile-dropdown-h .tag-mobile-dropdown.sc-tag3-mobile-dropdown {
		border: 1px solid #ebecf5;
		padding: 10px 15px;
		font-size: 14px;
		outline: none;
		border-radius: 4px;
	  }

	  .sc-tag3-mobile-dropdown-h .tag-mobile-dropdown__remove-label.sc-tag3-mobile-dropdown {
		display: none;
	  }

	  .tag-slider.sc-tag3-switch:checked{
		background-color: #68B590;
	  }

	  input.sc-tag3-switch:checked+.tag-slider-accent--navy.sc-tag3-switch {
		background-color: #68b590;
	  }

	  ::placeholder {
		color: #aeaeb3;
	  }

	  .sc-tag3-date-picker-h .tag-date-picker__input-container.sc-tag3-date-picker .tag-date-picker__calendar-icon-container.sc-tag3-date-picker {
		display: none;
	  }

	  .sc-tag3-date-picker-h .tag-date-picker__menu-icon-container.sc-tag3-date-picker {
		display: none;
	  }

	  .sc-tag3-date-picker-h .tag-date-picker__input-container-inputdisabled.sc-tag3-date-picker {
		height: 40px;
		padding: 5px 10px;
		width: 100%;
		font-family: "Muli";

		::placeholder {
		  color: #AEAEB3;
		}
	  }
	  &-textarea{
		display: block;
		height: 80px;
		padding: 5px 10px;
		width: 100%;
		resize: none;
		border: 1px solid #ebecf5;
		border-radius: 4px;
		margin-bottom: 20px;
		font-size: 14px;
		font-family: Muli, Arial;
		&::-webkit-input-placeholder {
		  color: #aeaeb3;
		}
		// &:focus-visible {
		//   border: 1px solid #ebecf5;
		// }
		&:focus-visible {
		  outline: none;
	  }
	  }
	}

	&-inputControl {
	  margin-bottom: 16px;
	}

	&-inputBox {
	  width: 100%;
	  font-size: $fontSize14;
	  color: #070707;
	  outline: none;
	  font-family: "Muli";
	  @include border-radius(4px);

	  .sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input.sc-tag3-mobile-date-time-input{
		border-bottom: 0px;
	  }
	  .tag3-input.sc-tag3-input {
		height: 40px;
		font-size: $fontSize14;
		color: #070707;
		font-weight: 400;
		font-family: Muli, Arial;
		border: 1px solid #EBECF5;
	  }

	  &-inputType {
		padding: 5px 10px;
		height: 40px;
		line-height: 1.5;
		border-radius: 2px;
		border: 1px solid #e2ecef;
		width: 100%;
		font-family: "Muli";

		&:focus-visible {
		  outline: none;
		}
	  }

	  .sc-tag3-input-h {
		width: 100%;
	  }
	}

	&-inputIcon {
	  position: absolute;
	  right: 10px;
	  top: 11px;
	  font-size: $fontSize25;
	  color: #6e6e6e;
	}

	&-button {
	  height: 45px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  flex: 0 0 auto;
	  flex-direction: column;
	  margin: 16px;
	}

	&-select {
	  height: 40px;
	  width: 100%;
	  overflow: hidden;
	  position: relative;
	  border-radius: 3px;
	  margin-bottom: 1em;
	}

	&-select:after {
	  content: url("../../images/down_icon.svg");
	  padding: 9px 0;
	  position: absolute;
	  right: 20px;
	  top: 0;
	  z-index: 1;
	  text-align: center;
	  width: 12px;
	  pointer-events: none;
	  height: 7px;

	  pointer-events: none;
	}

	&-select__field {
	  -webkit-appearance: none;
	  appearance: none;
	  border: 1px solid #ebecf5;
	  width: 100%;
	  padding: 10px 15px;
	  font-size: 14px;
	  color: #070707;
	  background: transparent;
	  outline: none;
	  font-family: "Muli";
	  border-radius: 4px;
	  -webkit-appearance: none;
	  /* for webkit browsers */
	  -moz-appearance: none;
	  /* for firefox */
	  appearance: none;

	  /* for modern browsers */
	  option {
		width: calc(100% - 400px);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	  }
	}

	/* remove default caret for ie */

	&-select__field::-ms-expand {
	  display: none;
	}

	&-wrapper {
	  height: 100%;
	  display: flex;
	  flex-direction: column;
	  overflow: auto;
	}

	&-inputBox_textarea {
	  display: block;
	  width: 100%;
	  height: 80px;
	  border: 1px solid #e2ecef;
	  padding: 5px 10px;

	  textarea#textAreaId {
		width: 100%;
		resize: none;
		border: 1px solid #ebecf5;
		border-radius: 4px;
		height: 120px;
		margin-bottom: 20px;
		font-size: 14px;
		font-family: Muli, Arial;

		// color: #000;
		&::-webkit-input-placeholder {
		  color: #aeaeb3;
		}
	  }
	}
  }
}

.showErrorMessage{
	.date-box, .input-box{
		.tag3-input.sc-tag3-input, .tag-mobile-date-time-input.sc-tag3-mobile-date-time-input{
			border: 2px solid #EA5876;
			background: #EA58760D;
		} 
	}
}
.errorBorder {
	border: 2px solid #EA5876;
	background: #EA58760D;
	&:focus {
		background-color: #EA58760D;
		outline: #EA5876;
		border: 2px solid #EA5876;
	}
}

.errorMessage {
	font-size: 14px;
	color: #EA5876;
	margin-top: 8px;
	font-weight: $fontWeight600;
}
.date-box {
	width: 100%;
	.tag-mobile-date-time-input.sc-tag3-mobile-date-time-input {
		border: 1px solid #EBECF5;
		height: 40px;
		align-items: center;
		padding: 0px 15px;
		border-radius: 4px;
	}
}
.input-box{
	width: 100%;
	.tag3-input.sc-tag3-input{
		border: 1px solid #EBECF5;
		height: 40px;
		align-items: center;
		padding: 0px 15px;
		border-radius: 4px;
	}
}
.sc-tag3-mobile-date-time-input-h .tag-mobile-date-time-input__remove-label.sc-tag3-mobile-date-time-input{
	display: none;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

// ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
// ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
// ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
//     color: transparent;
// }



// Media Queries
@media only screen and (max-width: 320px) {
  .export-attendance-report_title {
	font-size: 16px;
  }
}