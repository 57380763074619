@import '../../shared/variables.scss';
.pageHeader{
    display: flex;
    padding: 20px 15px;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: relative;
    
    &-curve{
        background-image: url("../../images/homeCurve.svg");
        width: 100%;
        height: 153px;
        position: absolute;
        top: -32px;
        z-index: -1;
        background-position: left;
        background-size: cover;
    }
    &-title{
        flex: 1 1 auto;
        font-size: $fontSize20;
        font-weight: $fontWeight400;
        color: $headerColor;
        span{
            font-weight: $fontWeight800;
        }
    }
    &-menuIcon{
        width:22px;
        height:22px;
        margin-right: 20px;
    }
    &-profileImg{
        img{
            width:38px;
            height: 38px;
            @include border-radius(50%);
            display: flex;
        }
    }
    &-clientLogo{
        width: 32px;
        height: 32px;
        img{
            width: 100%;
        }
    }
}

.app_header {
    &__user-details {
        display: flex;
        padding: 23px 15px 23px;
        background-color: $greyBackground;
        position: relative;
    }
    &__user-info {
        padding: 10px;
        margin-left: 6p;
    }
    &__close{
        position: absolute;
        top: 33px;
        right: 33px;
        width: 20px;
        height: 20px;
    }
    &__user-name{
        font-size: $fontSize16;
        font-weight: $fontWeight800;
        color: #2B2E34;
    }
    &__user-email{
        padding: 2px 0px;
        width: 185px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: $fontWeight600;
        color: #4E5054;

    }
    &__menuList{
        display: flex;
        align-items: center;
        padding: 14px 25px;
        &:active, &:hover, &:focus{
            background: #F5F5F7;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                width: 5px;
                height: 100%;
                background: #555b70;
            }
        }
        &-wrapper{
            height: 100%;
        }
        &-icon{
            width: 20px;
            height: 20px;
            margin-right: 15px;
        }
        &-text{
            font-size: $fontSize14;
            font-weight: $fontWeight600;
            color: $headerColor;
        }
    }
    &__branding{
        padding: 25px;
        flex: 0 0 auto;
        display: flex;
        img{
            width: auto;
            height: 75px;    
        }
    }
}
.pageHeaderNavigation {
    &.sc-tag3-mobile-side-menu-h .sc-tag3-mobile-side-menu{
        padding: 0px;
    }
}
.signOutText{
    color: #EA5876;
}